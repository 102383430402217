import React, { useEffect, useState } from "react";
import logo from "../../src/assets/logo.png";

const UnderConstruction = () => {
  const [displayText1, setDisplayText1] = useState("");
  const [displayText2, setDisplayText2] = useState("");
  const [fadeInStart, setFadeInStart] = useState(false);
  const text1 = "THIS WEBSITE IS";
  const text2 = "UNDER CONSTRUCTION";

  useEffect(() => {
    let currentIndex1 = 0;
    let currentIndex2 = 0;
    let interval1, interval2;

    // First animation
    interval1 = setInterval(() => {
      if (currentIndex1 <= text1.length) {
        setDisplayText1(text1.slice(0, currentIndex1));
        currentIndex1++;
      } else {
        clearInterval(interval1);
        
        // Start second animation after a brief pause
        setTimeout(() => {
          interval2 = setInterval(() => {
            if (currentIndex2 <= text2.length) {
              setDisplayText2(text2.slice(0, currentIndex2));
              currentIndex2++;
            } else {
              clearInterval(interval2);
              setFadeInStart(true);
            }
          }, 80);
        }, 400);
      }
    }, 100);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  return (
    <main
      className="w-full flex-col h-screen flex justify-center items-center bg-cover bg-center"
      style={{
        background:
          "linear-gradient(303deg, #3C3C3B 0%, #7C7C7C 100%) 0% 0% no-repeat padding-box, rgba(0, 0, 0, 0.7)",
      }}
    >
      {/* Logo Section */}
      <div className={`transition-all px-4 duration-1000 ${fadeInStart ? 'opacity-100' : 'opacity-0'}`}>
        <div className="flex items-center">
         <img src={logo} alt="logo" className="phone:w-[60%] phone:m-auto" />
        </div>
        <p className="text-[14px] phone:text-[8px] mt-2 text-center opacity-80 text-white font-medium">Progressive Construction</p>
      </div>

      {/* Main Text Section */}
      <div className="text-center space-y-4 mt-8">
        <h1 className="text-[55px] phone:text-[26px] md:text-6xl font-light text-white">
          {displayText1}
        </h1>
        <div className="text-[60px] phone:text-[32px] md:text-6xl font-bold text-[#FFE800]">
          {displayText2}
        </div>
      </div>

      {/* Keep in Touch Section */}
      <div className={`mt-[30px] phone:mt-[24px] text-center flex flex-col justify-center space-y-4 transition-all duration-1000 delay-500 ${fadeInStart ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
        <div className="w-36 h-[3px] phone:h-[2px] bg-[#FFE800] m-auto"></div>
        
        <div className="flex items-center justify-center !mt-[32px] phone:!mt-[16px]">
          <span className="opacity-80 text-white text-[26px] phone:text-[18px] leading-normal font-semibold">Keep in Touch</span>
        </div>

        <div className="text-[#FFE800] font-normal text-[20px] phone:text-[16px] mt-[8px]">
          Please email us at{" "}
          <a
            href="mailto:info@kbg.com.kw"
            className="hover:underline focus:outline-none transition-colors duration-300 hover:text-yellow-300"
          >
            info@kbg.com.kw
          </a>
        </div>
      </div>
    </main>
  );
};

export default UnderConstruction;